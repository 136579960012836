import image from "../images/webdevelopment.jpeg";
import { Link } from "react-router-dom";

function WebDevelopment() {
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center text-center text-md-start">
          <img
            src={image}
            alt="Graphic Design"
            className="img-fluid rounded-3 pb-3"
          />
        </div>
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center text-center text-md-start">
          <h1>Web Development</h1>
          <p className="lead">
            Our web development team excels in building custom websites tailored
            to your specific business needs. From sleek designs to seamless
            functionality, we prioritize user experience and strategic
            implementation to ensure your online presence is both visually
            stunning and highly functional.
          </p>
          <Link to="/portfolio" className="link">
            <button className="btn btn-outline-dark">Explore Now</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default WebDevelopment;
