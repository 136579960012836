import React, { useState, useEffect } from "react";

function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="container position-fixed bottom-0 w-100 mx-auto" style={{ zIndex: 1000, maxWidth: "960px" }}>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 bg-dark text-white rounded p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
          <p className="mb-3 mb-md-0 text-center text-md-left">
            We use cookies to improve your experience on our site. By using our
            site, you accept our privacy policy.
          </p>
          <button
            onClick={acceptCookies}
            className="btn btn-primary text-white"
          >
            Accept Cookies
          </button>
        </div>
      </div>
    </div>
  );
}

export default CookieConsent;
