import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function DynamicTitle() {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        document.title = "Home | Olunga Tech Creations";
        break;
      case "/about":
        document.title = "About | Olunga Tech Creations";
        break;
      case "/services":
        document.title = "Services | Olunga Tech Creations";
        break;
      case "/portfolio":
        document.title = "Portfolio | Olunga Tech Creations";
        break;
      case "/contact":
        document.title = "Contact | Olunga Tech Creations";
        break;
      default:
        document.title = "Olunga Tech Creations";
    }
  }, [location]);

  return null;
}
