import graphic from "../images/Graphic design.jpeg";
import web from "../images/web development.jpeg";
import app from "../images/mobile app development.jpeg";

function Solutions() {

  return (
    <div className="d-flex flex-column justify-content-center text-center container pt-5">
      <h1 className="display-5 mb-3">
        Diverse Creative Services
      </h1>
      <p className="lead mb-4">
        From graphic design to web and mobile development, we offer a wide range
        of innovative solutions.
      </p>
      <div className="row justify-content-center">
        <div
          className="col-md-4 mb-4 d-flex justify-content-center"
        >
          <ServiceCard
            image={graphic}
            title="Graphic Design"
            description="Unique and captivating designs tailored to enhance your brand identity."
          />
        </div>
        <div
          className="col-md-4 mb-4 d-flex justify-content-center"
        >
          <ServiceCard
            image={web}
            title="Web Development"
            description="Modern and responsive websites that drive engagement and conversions."
          />
        </div>
        <div
          className="col-md-4 mb-4 d-flex justify-content-center"
        >
          <ServiceCard
            image={app}
            title="Mobile App Development"
            description="Innovative apps that elevate user experiences and streamline business processes."
          />
        </div>
      </div>
    </div>
  );
}

function ServiceCard(props) {
  return (
    <div className="card" style={{ width: "18rem" }}>
      <img src={props.image} alt={props.title} className="card-img-top" />
      <div className="card-body">
        <h5 className="card-title">{props.title}</h5>
        <p className="card-text">{props.description}</p>
      </div>
    </div>
  );
}

export default Solutions;
