import React from "react";
import ReactDOM from "react-dom";
import "./css/bootstrap.css";
import "./js/bootstrap.bundle.min.js";
import "./js/script.js";
import "./css/styles.css";
import Home from "./pages/Home.jsx";
import About from "./pages/About.jsx";
import Services from "./pages/Services.jsx";
import Portfolio from "./pages/Portfolio.jsx";
import Contact from "./pages/Contact.jsx"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DynamicTitle from "./components/DynamicTitle.jsx";
import CookieConsent from "./components/CookieConsent.js";

const root = ReactDOM.createRoot(document.querySelector("#root"));
root.render(
  <BrowserRouter>
    <DynamicTitle />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
    <CookieConsent />
  </BrowserRouter>
);
