import Navbar from "../components/navbar";
import AboutHero from "../components/AboutHero";
import AboutStory from "../components/AboutStory";
import LastCallToAction from "../components/LastCallToAction"
import Footer from "../components/Footer";

function About() {
  return (
    <>
      <div className="about-hero">
        <Navbar />
        <AboutHero />
      </div>
      <AboutStory />
      <LastCallToAction />
      <Footer />
    </>
  );
}

export default About;
