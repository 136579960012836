import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import backgroundImage from "../images/bg.jpeg";

function LastCallToAction() {
  return (
    <div
      className="container d-flex align-items-center justify-content-center text-center text-white"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "400px",
        position: "relative",
      }}
    >
      <div
        className="overlay"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      ></div>
      <div className="row z-1" style={{ position: "relative", zIndex: 1 }}>
        <div
          className="col mb-4 mb-lg-0"
        >
          <h5 className="display-6">Ready to Elevate Your Brand?</h5>
          <p className="lead">
            Contact us today to discuss how we can craft compelling digital
            solutions for your business.
          </p>
          <Link to="/contact" className="text-white link">
            <button
              className="btn btn-primary btn-lg text-white"
            >
              Explore Now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LastCallToAction;
