import Navbar from "../components/navbar";
import ServicesHero from "../components/ServicesHero";
import GraphicDesign from "../components/GraphicDesign";
import WebDevelopment from "../components/WebDevelopment";
import MobileApp from "../components/MobileApp";
import LastCallToAction from "../components/LastCallToAction"
import Footer from "../components/Footer";

function Services() {
  return (
    <>
      <div className="services-hero">
        <Navbar />
        <ServicesHero />
      </div>
      <GraphicDesign />
      <WebDevelopment />
      <MobileApp />
      <LastCallToAction />
      <Footer />
    </>
  );
}

export default Services;
