import { useEffect } from "react";

import { Link } from "react-router-dom";
import about from "../images/about.jpeg";

function SmallAbout() {

  return (
    <div className="container pt-5">
      <div className="row">
        <div
          className="col-lg-4 d-none d-lg-flex justify-content-center mb-4 mb-lg-0"
        >
          <img src={about} alt="About me" className="rounded img-fluid" />
        </div>
        <div
          className="col-lg-8 text-center text-lg-start"
        >
          <h1 className="display-5 mb-4">
            About Olunga Tech Creations
          </h1>
          <p className="lead mb-4">
            Olunga Tech Creations specializes in graphic design, web development,
            and mobile app development, creating cutting-edge digital solutions for clients.
            <br />
            Founded by Cedric Olunga, our agency is driven by a passion for creativity
            and technology.
            <br />
            Whether you’re a startup looking to establish your digital presence or an
            established brand seeking to innovate, our team is here to elevate your business.
          </p>
          <Link to="/about" className="link">
            <button
              type="button"
              className="btn btn-outline-dark btn-lg px-4"
            >
              Read more
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SmallAbout;
