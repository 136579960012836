
function Footer() {
  return (
    <footer class="footer mt-auto py-3 bg-light">
      <div class="container text-center">
        <span class="text-muted"> &copy; Copyright 2024 Olunga Tech Creations | All Rights Reserved </span>
      </div>
    </footer>
  );
}

export default Footer;
