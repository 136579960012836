import React, { useState } from "react";

function ContactSection() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(""); // Add error state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setError("");

    try {
      const response = await fetch("https://portfolio.makeituseit.co.ke/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccess(true);
        setFormData({ name: "", email: "", message: "" });
      } else {
        setError("Failed to send message. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center align-items-md-start text-center text-md-start">
          <h1 className="display-5">Connect With Us Today</h1>
          <p className="lead">
            For inquiries about our services or to discuss your project, feel free to contact us.
          </p>
          <Contact name="Phone:" example="0708528186" />
          <Contact name="Email:" example="drolunga@gmail.com" />
          <Contact name="Address:" example="Kakamega" />
        </div>

        <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
          {/* Success Alert */}
          {success && (
            <div className="alert alert-success w-100 text-center" role="alert">
              Thank you! Your message has been sent.
            </div>
          )}

          {/* Error Alert */}
          {error && (
            <div className="alert alert-danger w-100 text-center" role="alert">
              {error}
            </div>
          )}

          {/* Form */}
          <form className="w-100 px-3" onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Your Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="name@example.com"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="4"
                placeholder="Write your message here..."
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-outline-dark w-100" disabled={loading}>
              {loading ? "Please wait..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

function Contact(props) {
  return (
    <div className="row">
      <p className="fw-bold">{props.name}</p>
      <p>{props.example}</p>
    </div>
  );
}

export default ContactSection;
