import demo from "../images/demo.png"
import flyer from "../images/flyer.png"
import logo from "../images/LOGO CONTEST.png"
import businessCard from "../images/typni business card-02.png"

function PortfolioSection() {
  return (
    <div className="container py-5">
      {/* Make It Use It Project */}
      <div className="row mb-5">
        <div className="col-12 col-md-6">
          <div className="card shadow-sm mb-4">
            <img src={demo} className="card-img-top" alt="Make It Use It Website" />
            <div className="card-body">
              <h5 className="card-title">Make It Use It</h5>
              <p className="card-text">
                We developed a fully responsive and user-friendly website for <a href="https://makeituseit.co.ke" target="_blank" rel="noopener noreferrer">makeituseit.co.ke</a>, a platform promoting sustainable living with actionable ideas for reusing everyday items.
              </p>
              <a href="https://makeituseit.co.ke" target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark">View Project</a>
            </div>
          </div>
        </div>
      </div>

      {/* Graphic Design Portfolio */}
      <div className="row">
        <div className="col-12 text-center">
          <h3 className="display-6">Graphic Design Portfolio</h3>
          <p className="lead">
            Our design services include stunning posters, banners, logos, and business cards that make your brand stand out.
          </p>
        </div>
      </div>

      {/* Bootstrap Carousel */}
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <div id="graphicDesignCarousel" className="carousel slide shadow-sm" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={flyer} className="d-block w-100" alt="Poster for Leading Initiatives For Education" />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Posters</h5>
                  <p>Creative posters</p>
                </div>
              </div>
              <div className="carousel-item">
                <img src={logo} className="d-block w-100" alt="Logo Design" />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Logos</h5>
                  <p>Unique logo designs that represent your brand identity.</p>
                </div>
              </div>
              <div className="carousel-item">
                <img src={businessCard} className="d-block w-100" alt="Business Card Design" />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Business Cards</h5>
                  <p>Professional business card designs that leave a lasting impression.</p>
                </div>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#graphicDesignCarousel" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#graphicDesignCarousel" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortfolioSection;
