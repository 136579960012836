import Footer from "../components/Footer";
import Navbar from "../components/navbar";
import PortfolioHero from "../components/PortfolioHero";
import PortfolioSection from "../components/PortfolioSection";

function Portfolio() {
  return (
    <>
      <div className="portfolio-hero">
        <Navbar />
        <PortfolioHero />
      </div>
      <PortfolioSection />
      <Footer />
    </>
  );
}

export default Portfolio;
