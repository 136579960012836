import HeroTemplate from "./HeroTemplate";

function AboutHero() {
  return (
    <HeroTemplate
      title="Meet Olunga Tech Creations"
      description="Bringing your visions to life with top-notch design and development services."
    />
  );
}

export default AboutHero;
