import { Link } from "react-router-dom";

function CallToAction() {

  return (
    <div className="container bg-danger rounded-4 p-5 mt-4">
      <div className="row align-items-center text-center text-lg-start">
        <div
          className="col-lg-8 mb-4 mb-lg-0"
        >
          <h5 className="display-6 text-white">Ready to transform?</h5>
          <p className="lead text-white">
            Take your brand to the next level with our tailored design and
            development solutions.
          </p>
        </div>
        <div
          className="col-lg-4 d-flex justify-content-center justify-content-lg-end"
        >
          <Link to="/contact" className="link">
            <button className="btn btn-light btn-lg">Explore now</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
