
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <div className="d-flex flex-column justify-content-center text-center">
      <h1 className="display-5 fw-bold text-white my-4" >
        Innovative Design Solutions
      </h1>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4 text-white">
          At Olunga Tech Creations, we transform your ideas into captivating
          visual experiences. Our expertise spans graphic design, web
          development, and mobile app solutions, delivering tailored services
          that elevate your brand.
        </p>
        <Link to="/portfolio" className="text-white link">
          <button
            type="button"
            className="btn btn-primary btn-lg px-4 gap-3 text-white"
          >
            Explore Now
          </button>
        </Link>
      </div>
    </div>
  );
}