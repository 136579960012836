import image from "../images/mobileappdevelopment.jpeg";
import { Link } from "react-router-dom";

function MobileApp() {
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center text-center text-md-start">
          <h1>Mobile App Development</h1>
          <p className="lead">
            Our mobile app development services combine cutting-edge technology
            with user-centric design to create intuitive and efficient
            applications. Whether you need a customer-facing app or an internal
            business tool, we have the expertise to bring your ideas to life on
            the mobile platform.
          </p>
          <Link to="/portfolio" className="link">
            <button className="btn btn-outline-dark">Explore Now</button>
          </Link>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-center">
          <img
            src={image}
            alt="Graphic Design"
            className="img-fluid rounded-3 pt-3"
          />
        </div>
      </div>
    </div>
  );
}

export default MobileApp;
