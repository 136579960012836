import makeituseit from "../images/demo.png";

function SuccessStories() {

  return (
    <div className="d-flex flex-column justify-content-center text-center container pt-5">
      <h1 className="display-5 mb-3">
        Explore Our Success Stories
      </h1>
      <p className="lead mb-4">
        Discover how our innovative strategies and designs have brought success
        to our clients through real-world case studies.
      </p>
      <div className="row mb-5">
        <div className="col-lg-5" >
          <img
            src={makeituseit}
            alt="makeituseit image"
            width="100%"
            height="250px"
            className="rounded-4"
          />
        </div>
        <div className="col-lg-7">
          <h5 className="h4 mt-3">Makeituseit</h5>
          <p className="mb-5 lead">
            Makeituseit, a leading charcoal briquette company, saw a 30% increase
            in user engagement after implementing our web development solutions.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SuccessStories;
