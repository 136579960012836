import Navbar from "../components/navbar";
import Hero from "../components/Hero";
import Solutions from "../components/Solutions";
import SmallAbout from "../components/SmallAbout";
import CallToAction from "../components/CallToAction";
import SuccessStories from "../components/SuccessStories";
import LastCallToAction from "../components/LastCallToAction";
import Footer from "../components/Footer";

export default function Home() {
  return (
    <>
      <div className="hero-section">
        <Navbar />
        <Hero />
      </div>
      <Solutions />
      <SmallAbout />
      <CallToAction />
      <SuccessStories />
      <LastCallToAction />
      <Footer />
    </>
  );
}
