import backgroundImage from "../images/bg.jpeg";

function AboutStory() {
  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col-12 col-md-4 d-flex justify-content-center text-center text-md-start">
          <h5 className="display-5">Our Story</h5>
        </div>
        <div className="col-12 col-md-8 d-flex justify-content-center text-center text-md-start">
          <p className="lead">
            Established by Cedric Olunga, Olunga Tech Creations has been at the
            forefront of innovative design and development solutions. <br />
            From startups to established brands, we have proudly served a
            diverse range of clients, helping them achieve their digital goals.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <img
            src={backgroundImage}
            alt="Image"
            className="rounded-4 img-fluid"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h1 className="display-5 pt-3 fw-medium text-center">
            Why Choose Us?
          </h1>
        </div>
      </div>
      <div className="row my-4">
        <WhyUs
          number="01"
          title="Creative Excellence"
          description="Delivering unique and visually stunning designs tailored to your brand identity."
        />
        <WhyUs
          number="02"
          title="Tech Innovation"
          description="Utilizing the latest technologies to develop cutting-edge websites and mobile apps."
        />
        <WhyUs
          number="03"
          title="Client-Centric Approach"
          description="Putting our clients first, we ensure transparent communication and exceptional customer service."
        />
      </div>
    </div>
  );
}

function WhyUs(props) {
  return (
    <div className="col-12 col-md-4 my-3">
      <div className="border-1 rounded shadow p-4 h-100">
        <h5 className=""> {props.number} </h5>
        <h5 className="h3 fw-bold"> {props.title} </h5>
        <p className="lead"> {props.description} </p>
      </div>
    </div>
  );
}

export default AboutStory;
