import HeroTemplate from "./HeroTemplate";

function ServicesHero() {
  return (
    <HeroTemplate
      title="Our Services"
      description="Empowering Your Brand with Creative Solutions in Design and Technology"
    />
  );
}

export default ServicesHero;
