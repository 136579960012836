import Navbar from "../components/navbar";
import ContactHero from "../components/ContactHero";
import ContactSection from "../components/ContactSection";
import LastCalllToAction from "../components/LastCallToAction";
import Footer from "../components/Footer";

function Contact() {
  return (
    <>
      <div className="contact-hero">
        <Navbar />
        <ContactHero />
      </div>
      <ContactSection />
      <LastCalllToAction />
      <Footer />
    </>
  );
}

export default Contact;
