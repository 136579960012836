import image from "../images/graphicdesign.jpeg";
import { Link } from "react-router-dom";

function GraphicDesign() {
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center text-center text-md-start">
          <h1>Graphic Design</h1>
          <p className="lead">
            Our graphic design services cover everything from logo creation to
            marketing collateral design. We focus on creating visually appealing
            and impactful designs that resonate with your target audience,
            helping your brand stand out in a crowded marketplace.
          </p>
          <Link to="/portfolio" className="link">
            <button className="btn btn-outline-dark">Explore Now</button>
          </Link>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-center">
          <img
            src={image}
            alt="Graphic Design"
            className="img-fluid rounded-3 pt-3"
          />
        </div>
      </div>
    </div>
  );
}

export default GraphicDesign;
