import HeroTemplate from "./HeroTemplate";

function PortfolioHero() {
  return (
    <HeroTemplate
      title="Our Portfolio"
      description="Take a look at some of our recent projects."
    />
  );
}

export default PortfolioHero;
