import HeroTemplate from "./HeroTemplate";

function ContactHero() {
  return (
    <HeroTemplate
      title="Get in Touch"
      description="Reach out to us for any queries or collaborations, we’re here to assist you!"
    />
  );
}

export default ContactHero;
